define("discourse/plugins/discourse-lti/discourse-lti/api-initializers/hide-lti-login-button", ["exports", "discourse/lib/api", "discourse/lib/decorators", "discourse/models/login-method"], function (_exports, _api, _decorators, _loginMethod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("0.8", api => {
    // LTI login must be initiated by the IdP
    // Hide the LTI login button on the client side:
    api.modifyClass("component:login-buttons", Superclass => class extends Superclass {
      buttons() {
        return super.buttons.filter(m => m.name !== "lti");
      }
      static #_ = (() => dt7948.n(this.prototype, "buttons", [_decorators.default]))();
    });

    // Connection is possible, but cannot be initiated
    // by Discourse. It must be initiated by the IdP.
    // Hide the button to avoid confusion:
    const lti = (0, _loginMethod.findAll)().find(p => p.name === "lti");
    if (lti) {
      lti.can_connect = false;
    }
  });
});